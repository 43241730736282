import { DocumentData, DocumentReference, QueryDocumentSnapshot, Timestamp, doc } from 'firebase/firestore';
import { Costing } from '../App/data/costing';
import { CreditNote } from '../App/data/creditNote';
import { Customer } from '../App/data/customers';
import { Invoice } from '../App/data/invoice';
import { BankEntry } from './BankEntry';
import { firestore } from './firebase';
import { DebitNote } from '../App/data/debitNote';
import dayjs from 'dayjs';
import _ from 'lodash';

export type BankSettlementForm = Omit<BankSettlement, 'customer' | 'bankEntries' | 'debitNotes' | 'costings' | 'creditNotes'> & {
  customer: string;

  bankEntries: AmountEntry<{ ref: string; type: BankEntry['entryType']; bankTransactionID: string }>[];
  debitNotes: AmountEntry<{ ref: string }>[];
  costings: AmountEntry<{ ref: string; jobNumber: string }>[];
  creditNotes: AmountEntry<{ ref: string }>[];
  invoices: AmountEntry<{ ref: string }>[];

  // date of creation of this entry
  issueDate: string;
  // date of marked settled
  settledDate: string;
};

type AmountEntry<T> = T & {
  amount: number;
  currency: string;
  date: Date;

  // Toby: name that user use to identify the transaction, which can be same as document ID
  label: string;
};

export interface BankSettlement extends DocumentData {
  customer: DocumentReference<Customer>;
  _customer?: Readonly<Customer>;

  bankEntries: {
    ref: DocumentReference<BankEntry>;
    label: string;
    type: BankEntry['entryType'];
    amount: number;
    date: Timestamp;
    currency: string;
    bankTransactionID: string;
  }[];

  debitNotes: { ref: DocumentReference<DebitNote>; label: string; amount: number; date: Timestamp; currency: string }[];
  costings: { ref: DocumentReference<Costing>; label: string; amount: number; date: Timestamp; currency: string; jobNumber: string }[];
  creditNotes: { ref: DocumentReference<CreditNote>; label: string; amount: number; date: Timestamp; currency: string }[];
  invoices: { ref: DocumentReference<Invoice>; label: string; amount: number; date: Timestamp; currency: string }[];

  balance: number;

  remarks: string;

  status: 'Draft' | 'Settled';

  issueDate: Timestamp;
  settledDate: Timestamp;

  // Toby: may need to store the conversion rate for each settlement
  // conversionRate: { [key: string]: number };
}

export const bankSettlementConverter = {
  toFirestore: (data: BankSettlementForm) => {
    const { customer, bankEntries, debitNotes, costings, creditNotes, invoices, issueDate, ...rest } = data;

    const convertAmountEntry = ({ ref, date, ...e }: AmountEntry<{ ref: string }>) => {
      return {
        ref: doc(firestore, ref),
        date: Timestamp.fromDate(date),
        ...e,
      };
    };

    return {
      ...rest,
      issueDate: Timestamp.fromDate(dayjs(issueDate).toDate()),
      settledDate: _.isNull(data.settledDate) ? null : Timestamp.fromDate(dayjs(data.settledDate).toDate()),
      customer: customer ? doc(firestore, customer) : undefined,

      bankEntries: bankEntries?.map(convertAmountEntry),
      debitNotes: debitNotes?.map(convertAmountEntry),
      costings: costings?.map(convertAmountEntry),
      creditNotes: creditNotes?.map(convertAmountEntry),
      invoices: invoices?.map(convertAmountEntry),
    } as BankSettlement;
  },
  fromFirestore: (snap: QueryDocumentSnapshot<BankSettlement>) => {
    const { bankEntries, customer, debitNotes, costings, creditNotes, invoices, issueDate, settledDate, ...data } = snap.data();
    function convertAmountEntry<T extends DocumentData, G>({
      ref,
      date,
      ...e
    }: {
      ref: DocumentReference<T>;
      date: Timestamp;
    } & G) {
      return {
        ref: ref.path,
        date: date?.toDate(),
        ...e,
      };
    }

    console.log(_.isNull(settledDate));
    return {
      _id: snap.id,
      settledDate: dayjs(settledDate?.toDate()).format('YYYY-MM-DD'),
      issueDate: dayjs(issueDate?.toDate()).format('YYYY-MM-DD'),
      ...data,
      customer: customer?.path,

      bankEntries: bankEntries?.map(convertAmountEntry),
      debitNotes: debitNotes?.map(convertAmountEntry),
      costings: costings?.map(convertAmountEntry),
      creditNotes: creditNotes?.map(convertAmountEntry),
      invoices: invoices?.map(convertAmountEntry),
    } as BankSettlementForm;
  },
};
