import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword, signOut, User, UserCredential } from 'firebase/auth';

// import dotenv from 'dotenv';
import { collection, doc, DocumentData, DocumentReference, getDoc, initializeFirestore, QueryDocumentSnapshot } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { createContext, useContext, useEffect, useState } from 'react';
import { costingConverter } from '../App/data/costing';
import { creditNoteConverter } from '../App/data/creditNote';
import { Customer } from '../App/data/customers';
import { DebitNote, debitNoteConverter } from '../App/data/debitNote';
import { Invoice, invoiceConverter } from '../App/data/invoice';
import { Configs } from '../App/pages/CustomerService/OrderForm/types';
import { bankEntryConverter } from './BankEntry';
import { bankSettlementConverter } from './BankSettlement';
import { generalLedgerConverter } from './GeneralLedger';
import { ledgerTypeConverter } from './ledgerTypes';

export type FirestoreRole = 
'Customer Service' | 'Warehouse' | 'Operator' | 'Router' | 'Business Manager' 
| 'Accounting' | 'Customer' | 'Monitor';
export type FirestoreFunctionalACL = 
'InvoiceED' | 'CostingED' 
| 'CustomerC' | 'CustomerD' | 'CustomerL' 
| 'OrderC' | 'OrderD' | 'OrderL' | 'Statistics' | 'StatementDL' | 'BankC' | 'BankED' 
| 'BankSettleED' | 'GeneralLedgerED'
// used yet:
| 'InvoiceC' | 'CostingC' | 'BankSettleC'| 'GeneralLedgerC' | 'BankC'
| 'InvoiceL' | 'CostingL' | 'BankSettleL'| 'GeneralLedgerL' | 'BankL'

| 'AddressC' | 'AddressL' | 'AddressED'
| 'MAWBC' | 'MAWBL' | 'MAWBED'
| 'DebitNoteC' | 'DebitNoteL' | 'DebitNoteED'
| 'CreditNoteC' | 'CreditNoteL' | 'CreditNoteED'
| 'CostingC' | 'CostingL' | 'CostingED'
| 'ChargeTableC' | 'ChargeTableL' | 'ChargeTableED'
;




export interface FirestoreUser {
  name: string;
  roles: FirestoreRole[];
  functionalRights: FirestoreFunctionalACL[];
  isAdmin?: boolean;
  isReadOnlyRole?: boolean;
}

// dotenv.config();

const firebaseConfig = JSON.parse(String(process.env.REACT_APP_FIREBASE_CONFIG));
console.log(firebaseConfig);
// Your web app's Firebase configuration
// export const firebaseConfig = {
//   apiKey: '',
//   authDomain: 'hyperway-firebase.firebaseapp.com',
//   projectId: 'hyperway-firebase',
//   storageBucket: 'hyperway-firebase.appspot.com',
//   messagingSenderId: '819864246726',
//   appId: '1:819864246726:web:875599d620b40de3f8bac7',
// };

const app = initializeApp(firebaseConfig);
const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true });

const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);

const functionInstance = axios.create({
  baseURL:
    // process.env.NODE_ENV === 'development'
    //   ? 'http://localhost:5001/palmify-dump-truck/us-central1/api'
    //   :
    'https://us-central1-palmify-dump-truck.cloudfunctions.net/api',
});

export const AuthContext = createContext<{ user: User; error: Error; loading: boolean; firestoreUser: FirestoreUser } | undefined>(
  undefined
);
auth.onAuthStateChanged(
  async (e) => {
    // console.log(e);
    if (e) {
    }
  },
  (e) => {
    console.log(e);
  }
);

export const AuthContextProvider = (props: any) => {
  const [user, setUser] = useState<User | null>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(true);
  const [firestoreUser, setFirestoreUser] = useState<FirestoreUser | null>();

  const auth = getAuth();
  // console.log(auth);
  // console.log(auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (e) => {
        setUser(e);
        // console.log(e);
        if (e) {
          const user = await getDoc(doc(firestore, 'users', e.uid) as DocumentReference<FirestoreUser>);
          setFirestoreUser(user.data());
        }
        setLoading(false);
      },
      (e) => {
        setError(e);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);
  return <AuthContext.Provider value={{ user, error, loading, firestoreUser }} {...props} />;
};

const handleLogin = async ({
  email,
  password,
  rememberMe = true,
  successCallback,
  errorCallback,
}: {
  email: string;
  password: string;
  rememberMe?: boolean;
  successCallback: (userCredential: UserCredential) => void;
  errorCallback: (error: any) => void;
}) => {
  // https://firebase.google.com/docs/auth/web/auth-state-persistence
  const persistance = browserLocalPersistence;
  setPersistence(auth, persistance)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    })
    .then(successCallback)
    .catch(errorCallback);
};
const handleLogout = ({ successCallback, errorCallback }: { successCallback: () => void; errorCallback: (error: any) => void }) => {
  signOut(auth).then(successCallback).catch(errorCallback);
};

const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: !!auth?.user };
};

// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
//   console.log('connected Firestore Emulator');
//   // connectFirestoreEmulator(firestore, 'localhost', 8080);
//   // console.log('connected Function Emulator');
// }

export { auth, firestore, functionInstance, functions, handleLogin, handleLogout, storage, useAuthState };

// https://medium.com/swlh/using-firestore-with-typescript-65bd2a602945
const genericConverter = <T extends DocumentData>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot<T>) => snap.data(),
});
const dataCollection = <T extends DocumentData>(collectionPath: string) =>
  collection(firestore, collectionPath).withConverter(genericConverter<T>());

const dataDocument = <T extends DocumentData>(collectionPath: string, documentPath: string) =>
  doc(firestore, collectionPath, documentPath).withConverter(genericConverter<T>());

export const db = {
  config: dataDocument<Configs>('configs', 'hyperway'),
  costings: collection(firestore, 'costing').withConverter(costingConverter),
  customers: dataCollection<Customer>('customers'),
  invoices: collection(firestore, 'invoice').withConverter(invoiceConverter),
  // invoices: dataCollection<Invoice>('invoice'),
  creditNotes: collection(firestore, 'creditNote').withConverter(creditNoteConverter),
  debitNotes: collection(firestore, 'debitNote').withConverter(debitNoteConverter),

  // for bank payable and bank receivable
  bankEntries: collection(firestore, 'bankEntries').withConverter(bankEntryConverter),
  bankSettlements: collection(firestore, 'bankSettlements').withConverter(bankSettlementConverter),

  generalLedgers: collection(firestore, 'generalLedgers').withConverter(generalLedgerConverter),
  ledgerTypes: collection(firestore, 'ledgerTypes').withConverter(ledgerTypeConverter),
};
